import React, { useContext, useState } from 'react'

const ModalContext = React.createContext()
const ModalContextToggle = React.createContext()

export const useModalContext = () => {
    return useContext(ModalContext)
}

export function useModalContextToggle() {
    return useContext(ModalContextToggle)
}

export const ModalProvider = ({ children }) => {
    const [ isModalOpen, setModalOpen ] = useState(false)
    const t = useModalContextToggle()

    const toggle = () => {
        setModalOpen((prevIsModalOpen) => {
            if(!prevIsModalOpen) {
                document.documentElement.classList.add('uk-modal-page')
                document.getElementById("signup-modal").style.display = "block"
                document.body.style.overflowY="scroll"
                setTimeout(()=>{
                    document.getElementById("signup-modal").classList.add('uk-open')
                },0)
            } else {
                document.getElementById("signup-modal").classList.remove('uk-open')
            }
            return !prevIsModalOpen});
    }

    return (
        <ModalContext.Provider value={isModalOpen}>
            <ModalContextToggle.Provider value={toggle}>
                {children}
            </ModalContextToggle.Provider>
        </ModalContext.Provider>
    )
}