// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"


// gatsby-browser.js
import React from 'react';
import {ModalProvider} from './src/components/modalContext'

export const wrapRootElement = ({ element }) => {
  return (
    <ModalProvider>{element}</ModalProvider>
  );
}